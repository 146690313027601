import PageController from 'abstract/PageController';
import LoginPageView from './LoginPageView';
import { PopupIcon, PromptPopup, AlertPopup, ErrorPopup, LoadingPopup } from 'van-beek-framework/components/ui/popups';
import ConfiguratorNetworkManager from 'core/ConfiguratorNetworkManager';

export default class LoginPageController extends PageController {
    constructor() {
        super(new LoginPageView());
        this.subscribeTo(this._view);
    }

    subscribeTo(view) {
        super.subscribeTo(view);
        this._formSubmissionSubscription = view.formSubmissionEvent.subscribe(this.onFormSubmission.bind(this));
        this._resetPasswordSubscription = view.resetPasswordButtonEvent.subscribe(this.onResetPassword.bind(this));
    }

    unsubscribeFrom(view) {
        super.unsubscribeFrom(view);
        this._formSubmissionSubscription.unsubscribe();
        this._resetPasswordSubscription.unsubscribe();
    }

    onResetPassword(_, emailUserInput) {
        PromptPopup.show({
            title: "page-login.popup-forgot-password.title".localized,
            message: "page-login.popup-forgot-password.message".localized,
            placeholder: "page-login.popup-forgot-password.input-email".localized,
            value: emailUserInput
        }).then(data => {
            LoadingPopup.show();

            ConfiguratorNetworkManager.sendPasswordResetLink(data.value, (_, error) => {
                LoadingPopup.hide();

                if (error == null) {
                    AlertPopup.show({
                        title: "page-login.popup-forgot-password.alert-success.title".localized,
                        message: `${"page-login.popup-forgot-password.alert-success.message-email-sent".localized}<br><strong>${"page-login.popup-forgot-password.alert-success.message-alert".localized}</strong>`,
                        icon: new PopupIcon("times-circle", "#A3CB38")
                    });
                } else {
                    ErrorPopup.show({
                        title: "page-login.popup-forgot-password.alert-failure.title".localized,
                        message: "page-login.popup-forgot-password.alert-failure.message".localized
                    });
                }
            });
        }).catch(() => {});
    }

    onFormSubmission(view, validatePassword, email, password) {
        let valid = true;

        if (validatePassword && password.length <= 0) {
            view.highlightInvalidPassword();
            valid = false;
        }

        if (email.length <= 0) {
            view.highlightInvalidEmail();
            valid = false;
        }

        if (!valid) {
            return;
        }

        view.submitForm();
    }
}