import "css/pages/Login/LoginPageView.scss";

import PageView from 'abstract/PageView';
import { EventEmitter } from 'van-beek-framework/events';

export default class LoginPageView extends PageView {

    formSubmissionEvent = new EventEmitter(this);
    resetPasswordButtonEvent = new EventEmitter(this);

    _populate() {
        super._populate();

        this.$form = this.$elem.find("#login-form form");

        this.$emailInput = this.$elem.find("#login-form input[name='username']");
        this.$passwordInput = this.$elem.find("#login-form input[name='password']");

        this.$loginButton = this.$elem.find("button[name='login-pass']");
        this.$loginLinkButton = this.$elem.find("button[name='login-link']");
        this.$resetPasswordButton = this.$elem.find(".button__minimalist[data-action='reset-password']");
    }

    _setupEvents() {
        super._setupEvents();

        this.$resetPasswordButton.on('click', () => {
            this.resetPasswordButtonEvent.notify(this.$emailInput.val());
        });

        this.$loginButton.on('click', () => {
            this._submitter = this.$loginButton;
        });

        this.$loginLinkButton.on('click', () => {
            this._submitter = this.$loginLinkButton;
        });

        this.$form.on('submit', e => {
            e.preventDefault();
            let submitter = this._submitter;

            //Only skip password check if submitter is loginLinkButton, default to true
            this.formSubmissionEvent.notify(submitter !== this.$loginLinkButton, this.$emailInput.val(), this.$passwordInput.val());

            this._submitter = null;
            return false;
        });

        this.$emailInput.on('input', this._hideInvalidHighlight.bind(this, this.$emailInput));
        this.$passwordInput.on('input', this._hideInvalidHighlight.bind(this, this.$passwordInput));

        setTimeout(() => {
            this._focusDefaultInput();
        }, 0);
    }

    _focusDefaultInput() {
        if (this.$emailInput.val().length <= 0) {
            this.$emailInput.trigger('focus');
            this.$emailInput.trigger('select');
        } else {
            this.$passwordInput.trigger('focus');
        }
    }

    highlightInvalidEmail() {
        this.$emailInput.attr("invalid", true);
        this.$emailInput.trigger('focus');
    }

    highlightInvalidPassword() {
        this.$passwordInput.attr("invalid", true);
        this.$passwordInput.trigger('focus');
    }

    _hideInvalidHighlight($input) {
        $input.removeAttr("invalid");
    }

    submitForm() {
        this._allowFormSubmission = true;
        let submitterName = "login-pass";

        if (this._submitter != null) {
            submitterName = this._submitter.attr('name');
        }

        this.$form.append(`<input type="hidden" name="${submitterName}" value="true" />`);
        this.$form[0].submit();
    }

}